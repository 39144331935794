<template>
  <tr>
    <td style="min-width: 200px">
      <vField
          v-model="item.product_costingable_id"
          name="product_costingable_id"
          type="text"
          class="form-control d-none"
      />
      <AsyncSelect
          :api-service="fetchProductList"
          placeholder="Select Product"
          v-model="selectedProduct"
          :additional-query="additionalContactOption"
          :format-label="option => option.text"
          :additional-option="additionalOptions"
          label="text"
          :disabled="route.query.sales_general_id && itemType === 'main_product'"
      />
    </td>
    <td class="type-selection-td">
      <v-select
        v-model="item.product_type"
        placeholder="Select Production Type"
        label="name"
        :options="costingProductTypes"
        :reduce="costing => costing.value"
        :disabled="itemType === 'main_product'"
      />
    </td>
    <td>
      <div class="td-inline">
        <input
          type="text" class="form-control text-center"
          placeholder="Unit"
          :value="item.unit_of_measurment"
          readonly
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            v-model="item.quantity"
            type="number"
            class="form-control text-center"
            placeholder="Qty"
            :min="0"
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            :value="totalCost"
            type="number"
            class="form-control text-center"
            placeholder="Total Mnf Cost"
            :min="0"
            readonly
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            @keyup="getChangeCostPercentage(index)"
            v-model="item.wastage"
            type="number"
            class="form-control text-center"
            placeholder="Cost %"
            :min="0"
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            :value="finalAmount"
            placeholder="Amount"
            type="text"
            class="form-control text-center"
            readonly
        />
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            :value="perUnitCost"
            placeholder="Per Unit Cost"
            type="text"
            class="form-control text-center"
            readonly
        />
      </div>
    </td>
    <td v-if="isShowDeleteBtn">
      <div>
        <button @click="(e) => deleteItem(e, index)" class="btn btn-sm btn-dlt btn-danger">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </td>
  </tr>
</template>

<script setup>
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {computed, ref, watch} from 'vue';
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";

const props = defineProps(['item', 'index', 'onDeleteItem', 'totalCost', 'isShowDeleteBtn', 'costingProductTypes', 'itemType'])
const emit = defineEmits(['onDeleteItem', 'costPercentageCheck']);
const route = useRoute();
const {fetchProductList} = handlePurchaseAndSales()
const selectedProduct = ref(props.item.product_costingable);
const isInitialLoaded = ref(true);

const additionalContactOption = ref({
  average_price: true
});

const additionalOptions = computed(() => {
  if (route.params.productCostingId && props.item.product_costingable_id && props.item.product) {
    return [
      {
        id: props.item.product_costingable_id,
        text: props.item.product.name,
      }
    ]
  }
});

watch(selectedProduct, (newValue) => {
  if (newValue && (!isInitialLoaded.value || !route.query.sales_general_id)) {
    props.item.product = {
      name: newValue.text,
      ...newValue
    }
    if(! props.item.unit_of_measurment) props.item.unit_of_measurment = newValue.description.unit.name;
    props.item.product_costingable_id = newValue.id;
  }
});

watch(() => props.item.product, (newValue) => {
  
  if(! newValue || ! isInitialLoaded.value) return;

  selectedProduct.value = {
    id: newValue.id,
    name: newValue.name,
    text: newValue.name
  }
  
  isInitialLoaded.value = false;
})

const getChangeCostPercentage = (index) => {
  if (props.item.wastage > 100) {
    props.item.wastage = 100
  }
  emit('costPercentageCheck', index, props.item.wastage)
}

const finalAmount = computed(() => {
  const amount = props.item.rate * props.item.quantity;
  props.item.amount = amount.toFixed(4);
  return props.item.amount;
});

const perUnitCost = computed(() => {
  const rate = ((props.totalCost * props.item.wastage) / 100) / props.item.quantity;
  props.item.rate = rate.toFixed(4);
  return props.item.rate;
});
const deleteItem = (e, i) => {
  e.stopPropagation();
  emit('onDeleteItem', i)
}
</script>

<style>
.type-selection-td {
  min-width: 200px;
}
.create-billing > :not(first-child) > * {
  padding-left: 10px !important;
}

.create-billing > :not(caption) > * > * {
  padding: 0.72rem 0.2rem;
}
</style>
