<template>
  <div class="card border-1">
    <div class="table-container">
      <table class="table create-billing table-width">
        <thead>
          <tr class="bg-black-light">
            <td class="font-medium-1 p-25" colspan="100%">{{ tableTitle }}</td>
          </tr>
          <tr>
            <th>#</th>
            <th>Item Description</th>
            <th>UoM</th>
            <th>Qty</th>
            <th>Wastage %</th>
            <th>Wastage Qty</th>
            <th>Total Qty</th>
            <th>Rate</th>
            <th>Amount</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody ref="tableRef">
          <MaterialComponentItem
              v-for="(item, i) in tableItems"
              :key="i"
              :item="item"
              :index="i"
              @onDeleteItem="onDeleteItem"
          />
          <tr>
            <td colspan="2">
              <button @click="addNewItem" class="btn btn-outline-primary btn-sm">
                <i class="fas fa-plus"></i>
                Add new
              </button>
            </td>
            <th colspan="6" class="text-center">Total</th>
            <th class="text-center">{{ getTotal }}</th>
          </tr>
        </tbody>
      </table>
      <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from 'vue';
import MaterialComponentItem from "./MaterialComponentItem.vue";

const props = defineProps({
  tableTitle: {
    type: String
  },
  tableItems: {
    type: Array
  },
  type: {
    type: String,
    default:'raw_materials'
  }
})
const getTotal = computed(() => {
  return props.tableItems.reduce((total, amount) => total + parseFloat(amount.amount), 0).toFixed(4);
})

const addNewItem = () => {
  props.tableItems.push({
    for_total_cost: true,
    id: null,
    product: null,
    product_costingable_id: null,
    product_costingable_type: 'App\\Models\\Product',
    quantity: 1,
    wastage: 0,
    wastage_quantity: 0,
    total_quantity: 0,
    rate: 0,
    amount: 0,
    type: props.type
  })
}
const onDeleteItem = (index) => {
  props.tableItems.splice(index, 1);
}
</script>

<style>
.create-billing > :not(first-child) > * {
  padding-left: 10px !important;
}

.create-billing > :not(caption) > * > * {
  padding: 0.72rem 0.2rem;
}

</style>
